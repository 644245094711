.MuiListItemButton-root {
    background-color: $lightgray;
}

.active {
    .MuiTouchRipple-root {
        background-color: $mediumOrange;
    }
    .MuiTypography-root {
        color: $paper;
        position: relative;
        z-index: 9999;
    }
    .MuiListItemIcon-root {
        color: $paper;
        position: relative;
        z-index: 9999;
    }
}

.MuiChip-root {
    &:hover {
        background-color: $lightgray;
        svg {
            fill: $paper;
        }
    }
}

.MuiAvatar-root {
    background-color: $lightgray;
    transition: all 0.2s;

    &:hover {
        background-color: $blue;
        svg {
            fill: $paper;
        }
    }
}
.header__settings {
    margin-bottom: 15px !important;
}

.header__settings__icon {
    color: red;
}

.nav__list {
    display: flex;
    flex-direction: column;
    gap: 8px;
}
