.infinitescroll__container {
    &__card {
        padding: 10px 0px 10px 0px;
        margin: 0px 0px 15px 0px;
        background-color: $lightgray;
        border-radius: 5px;
        cursor: pointer;
        transition: all 0.2s;
        &:hover {
            background-color: $grey300;
            box-shadow: $shadow;
        }
    }

    &__card:last-child {
        margin: 0px 0px 0px 0px;
    }

    &__text {
        font-weight: bold;
    }

    &__message {
        text-align: center;
    }
}
