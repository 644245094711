.body,
html {
    // overflow: hidden;
}

.scrollable__container {
    overflow-y: auto;
    max-height: calc(80vh);
}

// Custom Scrollbar
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: #f5f5f5;
}

::-webkit-scrollbar {
    width: 8px;
    background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: $darkOrange;
}

.centered__parent {
    display: flex;
    justify-content: center;
}

.login__backdrop {
    z-index: 1201;
    background: #eeeeee;
    .login__spinner {
        position: absolute;
    }
}

.snackbar__container {
    margin: 2px;
}

.changepassword {
    &__password {
        margin-bottom: 10px;
    }

    &__confirm {
        margin-bottom: 20px;
    }
}

.forgotpassword {
    &__text {
        margin-bottom: 20px;
    }

    &__button {
        margin-bottom: 16px;
        margin-right: 15px;
    }

    &__icon {
        margin-right: 5px;
        margin-bottom: 2px;
    }
}

.register {
    &__strength {
        width: 85px;
        height: 8px;
        border-radius: 7px;
    }
}

.profile {
    &__textfield {
        margin-bottom: 10px;
    }

    &__button {
        margin-left: auto;
        margin-top: 20px;

        &__container {
            display: flex;
        }
    }
}

.button__greyBackground {
    &:hover {
        background-color: $grey300;
    }
}

.regular__cursor {
    cursor: initial !important;
}