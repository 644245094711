.debuglogs {
    height: calc(100vh - 180px);
    .site__selection {
        padding-bottom: 20px;
    }

    .status {
        font-size: 30px;
        padding-bottom: 30px;

        .text {
            flex-grow: 1px;
            margin-bottom: 40px;
        }
    }

    .tools {
        float: right;
        .button {
        }

        .scroll__switch {
        }
    }

    .console {
        color: white;
        border-radius: 5px;
        padding: 20px;
        font-family: monospace;
        unicode-bidi: embed;
        background-color: #333333;
        overflow-y: auto;
        height: calc(100vh - 300px);
    }
}
