// heatmap controls
.mapboxgl-ctrl-attrib-button {
    display: none;
}

// heatmap links
.mapboxgl-ctrl-attrib-inner {
    display: none;
}

// heatmap logo
.mapboxgl-ctrl-bottom-left {
    display: none;
}

@media (min-width: 858px) {
    .site__gauge {
        display: flex;
    }
}

.site__card {
    width: 100%;
    box-shadow: $shadow;
    // box-shadow: 0px 0px 20px 5px rgba(0, 0, 0, 0.05);
    border-radius: 5px;
    background-color: #ffffff;

    &__value {
        color: $darkOrange;
        font-size: 30px;
        text-align: center;
        font-weight: bold;
        padding-bottom: 10px;
        &__error {
            text-align: center;
            font-weight: bold;
            color: red;
        }
    }

    &__title {
        text-transform: uppercase;
        padding-bottom: 10px;
        padding-top: 10px;
        text-align: center;
        font-weight: bold;
    }
}
.site__borne__button {
    background-color: #f5f5f5;
    border-radius: 5px;
    cursor: pointer;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.2s;
    &:hover {
        box-shadow: $shadow;
    }
}

.site__number__container {
    display: flex;
    // gap: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
}
